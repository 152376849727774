/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 225px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 100%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGgABAQACAwAAAAAAAAAAAAAAAAMBAgQFBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHsJx4k16RFU0wG4P/EABoQAAMBAAMAAAAAAAAAAAAAAAECAwQAEBL/2gAIAQEAAQUC0XMmTQ3WyZ9yBLyUpJgGCqEHP//EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAwEBPwGBj//EABURAQEAAAAAAAAAAAAAAAAAACAh/9oACAECAQE/AaP/xAAfEAEAAgEDBQAAAAAAAAAAAAABAhEAEBMhIjFBYZH/2gAIAQEABj8CiFN+MGfB603KsD5lwjKhyMXuZTyZ0mn/xAAcEAADAAMAAwAAAAAAAAAAAAAAAREhMVEQkdH/2gAIAQEAAT8hW+y8huBcOOiaaqyiXb2goUtSm/g8Gog0rptE5Dx//9oADAMBAAIAAwAAABAczzz/xAAXEQEAAwAAAAAAAAAAAAAAAAABICEx/9oACAEDAQE/EBs3D//EABcRAQADAAAAAAAAAAAAAAAAAAERICH/2gAIAQIBAT8QWWU//8QAHhABAAICAwADAAAAAAAAAAAAARExACFBUWEQgaH/2gAIAQEAAT8QLwMpB1EM9Xjdv0JmM85g1+9YCQQkSkyuyxNgPJbPnOQryZB1Ta0ZfowngaStYPsMJTiwhWXtffj/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"cincinnati bengals\"\n        title=\"cincinnati bengals\"\n        src=\"/static/5b13aab10f3c7fac8b6c43b0b00e9ab6/275f3/cincinnati-bengals.jpg\"\n        srcset=\"/static/5b13aab10f3c7fac8b6c43b0b00e9ab6/4d5fb/cincinnati-bengals.jpg 166w,\n/static/5b13aab10f3c7fac8b6c43b0b00e9ab6/275f3/cincinnati-bengals.jpg 225w\"\n        sizes=\"(max-width: 225px) 100vw, 225px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "As with a lot of teams in the NFL, the ", React.createElement(_components.strong, null, "Cincinnati Bengals"), " began in the AFL. "), "\n", React.createElement(_components.p, null, "It was ", React.createElement(_components.strong, null, "1966"), " when the Bengals got their start. "), "\n", React.createElement(_components.p, null, "Interestingly, it was former Brown’s head coach, ", React.createElement(_components.a, {
    href: "/cleveland-browns-start/"
  }, "Paul Brown"), ", that vied for and received the franchise. "), "\n", React.createElement(_components.p, null, "Paul Brown had led the ", React.createElement(_components.strong, null, "Browns"), " to many victories during his time with ", React.createElement(_components.strong, null, "Cleveland"), " so he probably felt slighted by being released from  a team he built from the ground up (not to mention the Browns were named after him). "), "\n", React.createElement(_components.p, null, "As a result of being released from the Browns, Paul developed an interest in initiating a pro football franchise in ", React.createElement(_components.a, {
    href: "/ohio-home-buckeyes/"
  }, "Ohio"), ".  He would name this new team the Bengals as a tribute to the Bengals that played in Cincinnati from 1937 to 1942. "), "\n", React.createElement(_components.p, null, "Not surprisingly, he chose ", React.createElement("u", null, "orange and black"), " as the team’s colors however it just so happened that the shade of orange chosen was the exact same as that used by the Browns. "), "\n", React.createElement(_components.p, null, "Because the Bengals found themselves in the same division as the Browns after the ", React.createElement(_components.strong, null, "1970 AFL-NFL"), " merger the two teams became ", React.createElement(_components.strong, null, "instant rivals"), ". "), "\n", React.createElement(_components.p, null, "The Bengals also have a professional cheerleading squad known as the ", React.createElement(_components.strong, null, "Ben-Gals"), "."), "\n", React.createElement(_components.p, null, "Cincinnati Bengals logo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
